/* eslint-disable no-prototype-builtins */
/* eslint-disable no-case-declarations */
import { useContext } from 'react';
import { Abilities, roleAbilitiesType, subscriptionAbilitiesType } from '../models/user.models';
import { isEmpty } from 'lodash';
import { Wound } from '../models/wound.models';
import { Field, TextOperation, WCPRecordType, WoundStatus } from '../models/global.models';
import moment from 'moment';
import { format } from 'date-fns';
import FMService from '../services/fm.service';
import i18nCustom from 'src/assets/locales/i18n';
import i18n from 'i18next';
import { AppMode, AppModes, Config, Structure, User } from '../models/session.models';
import AppContext from 'src/core/context/global/AppContext';
import { AppInstance } from './instance.utils';

export const namePattern = /^[a-zA-Z0-9\s]*$/;
export const usernamePattern = /^[a-zA-Z0-9\s._@]*$/;
export const siteNamePattern = /^[a-zA-Z0-9 ]{3,}$/;
export const webPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
export const webPatternV2 = /^(https?:\/\/)(www\.)?([\da-zA-Z.-]+)\.([a-zA-Z.]{2,6})([/\w .-]*)*\/?$/;
export const contactEmailPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const alphanumericValidRegex = /^[a-zA-Z0-9 ]*$/;
// eslint-disable-next-line no-useless-escape
export const contactPhonePattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{1,10}$/im;
export const floatOrNumberPattern = /^\d*\.?\d*$/;
export const testingSites = ['1387', '1493'];
export const hideFeature = false;
export const appToAppPages = ['wounds', 'gallery'];

export const isParseServerEnabled = (config: Config) => {
  return config.parseServer && config.parseServer.enabled;
};

export enum ExportFormat {
  CSV = 'csv',
  PDF = 'pdf',
  ZIP = 'zip',
}

export enum Opacity {
  Disabled = '50%',
  Enabled = '100%',
}

export enum FormMode {
  Add = 'add',
  Edit = 'edit',
  LINK = 'link',
}

export enum DateSeparator {
  dash = '-',
  slash = '/',
}
export function newDateFormat(
  date: string | Date,
  country?: string,
  customDateFormat?: string,
  withTimeStamp?: boolean,
  separator?: DateSeparator,
) {
  const selectedCountry = country ? country : 'United States';
  const dateFormat = customDateFormat ? customDateFormat : getCountryDateFormat(selectedCountry, separator);
  const DateFormat = withTimeStamp ? dateFormat + ' HH:mm' : dateFormat;

  return moment(date).format(DateFormat);
}

export function getCountryDateFormat(country: string, separator?: DateSeparator): string {
  const dateSeparator = separator ?? DateSeparator.slash;
  let dateFormat: string;

  switch (country.toLowerCase()) {
    case 'united states':
      dateFormat = 'MM/DD/YYYY';
      break;
    case 'china':
    case 'taiwan':
    case 'taiwan, province of china':
    case 'korea':
    case "korea, democratic people's republic of":
    case 'korea, republic of':
    case 'japan':
    case 'canada':
      dateFormat = 'YYYY/MM/DD';
      break;
    default:
      dateFormat = 'DD/MM/YYYY';
      break;
  }

  if (dateSeparator === DateSeparator.dash) {
    dateFormat = dateFormat.replace(/\//g, '-');
  }

  return dateFormat;
}
export const DateFormatCountryMapping: any = {
  us: 'MM/DD/YYYY',
  usa: 'MM/DD/YYYY',
  US: 'MM/DD/YYYY',
  USA: 'MM/DD/YYYY',
  'United States': 'MM/DD/YYYY',
  China: 'YYYY/MM/DD',
  Taiwan: 'YYYY/MM/DD',
  'Taiwan, Province of China': 'YYYY/MM/DD',
  Korea: 'YYYY/MM/DD',
  "Korea, Democratic People's Republic of": 'YYYY/MM/DD',
  'Korea, Republic of': 'YYYY/MM/DD',
  Japan: 'YYYY/MM/DD',
  Canada: 'YYYY/MM/DD',
  canada: 'YYYY/MM/DD',
};

export const getUserRole = (user: User, structure: Structure): Structure => {
  if (user.structures === undefined) {
    return { ...structure, role: user.role, getDateFormat: structure.getDateFormat || (() => 'default-date-format') };
  }
  return structure;
};

export const can = (structure: Structure | undefined, actions: string[], abilities: Abilities): boolean => {
  const role = structure?.role;
  if (!role || !abilities?.roleAbilities?.[role as keyof roleAbilitiesType]) {
    return false;
  }

  const roleActions = abilities.roleAbilities[role as keyof roleAbilitiesType];
  const instanceActions = abilities.instanceAbilities;

  const isInInstanceActions = (action: string) => (instanceActions ? instanceActions.includes(action) : true);

  if (structure?.type === 'account' || structure?.type === 'site') {
    try {
      const subscriptionPlan = structure.ownSubscription?.[0]?.subscription_plan;
      if (!subscriptionPlan || !abilities?.subscriptionAbilities?.[subscriptionPlan as keyof subscriptionAbilitiesType]) {
        return actions.every(action => roleActions?.includes(action) && isInInstanceActions(action));
      }

      const subscriptionActions = abilities.subscriptionAbilities[subscriptionPlan as keyof subscriptionAbilitiesType];
      return actions.every(action => roleActions?.includes(action) && subscriptionActions.includes(action) && isInInstanceActions(action));
    } catch (ex) {
      return false;
    }
  }
  return actions.every(action => roleActions?.includes(action) && isInInstanceActions(action));
};

export const isPositiveIntegerString = (str: string) => {
  return /^[1-9]\d*$/.test(str);
};

export const dateToMySQLFormatter = (date: string, format: string) => {
  if (!date) {
    return '';
  }

  const allSpecialChars = /[ `!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]/g;
  const splitterMatch = String(format).toLowerCase().match(allSpecialChars);
  const splitter = splitterMatch ? splitterMatch[0] : '';
  const indexdd = String(format).toLowerCase().split(splitter).indexOf('dd');
  const indexmm = String(format).toLowerCase().split(splitter).indexOf('mm');
  const indexyyyy = String(format).toLowerCase().split(splitter).indexOf('yyyy');
  const dateArray = date.split(splitter).filter(item => !isEmpty(item));

  const dd = dateArray[indexdd] ?? '01';
  const mm = dateArray[indexmm] ?? '01';
  const yyyy = dateArray[indexyyyy] ?? '1970';

  return `${yyyy}-${mm}-${dd}`;
};

export const getPatternByDateFormat = (dateFormat: string) => {
  const allSpecialChars = /[ `!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]/g;
  const matchResult = dateFormat?.match(allSpecialChars);
  const splitter = matchResult ? matchResult[0] : '';

  const ddPattern = '(0[1-9]|[12][0-9]|3[01])';
  const mmPattern = '(0[1-9]|1[012])';
  const yyyyPattern = '((19|18)[0-9]{2}|2[0-9][0-9][0-9])';

  const ddIndex = String(dateFormat).toLowerCase().split(splitter).indexOf('dd');
  const mmIndex = String(dateFormat).toLowerCase().split(splitter).indexOf('mm');
  const yyyyIndex = String(dateFormat).toLowerCase().split(splitter).indexOf('yyyy');

  const patternArray = [];
  patternArray[ddIndex] = ddPattern;
  patternArray[mmIndex] = mmPattern;
  patternArray[yyyyIndex] = yyyyPattern;

  return patternArray.join(`\\${splitter}`);
};
const capitalizeFirstLetter = (string: string) => {
  return string.replace(/\b(\w)/g, s => s.toUpperCase());
};
export const capitalizeFirstLetterV2 = (text: string): string => {
  if (text.length === 0) {
    return text;
  }
  return text.charAt(0).toUpperCase() + text.slice(1).toLocaleLowerCase();
};

const getDaysRemaining = (startDateString: string, endDateString: string) => {
  if (endDateString === 'N/A') {
    return 'N/A';
  }

  const today = moment().startOf('day');
  const endDate = moment(endDateString, 'YYYY-MM-DD');
  const startDate = moment(startDateString, 'YYYY-MM-DD');
  if (!endDate.isValid() || today.isBefore(startDate) || today.isAfter(endDate)) {
    return 'N/A';
  }

  // Set the locale for moment using the current language from i18n
  moment.locale(i18n.language);
  const diff = moment.duration(endDate.diff(today)).humanize();

  return capitalizeFirstLetter(diff);
};

export default getDaysRemaining;

export const formatSQLDateByDateFormat = (date: string, format: string) => {
  if (!date || !format) {
    return '';
  }

  const [yyyy, mm, dd] = date.split('-');
  const allSpecialChars = /[ `!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]/g;
  const matchResult = format?.match(allSpecialChars);
  const splitter = matchResult ? matchResult[0] : '';
  const indexdd = String(format)?.toLowerCase().split(splitter).indexOf('dd');
  const indexmm = String(format)?.toLowerCase().split(splitter).indexOf('mm');
  const indexyyyy = String(format)?.toLowerCase().split(splitter).indexOf('yyyy');
  const myDateArray: string[] = [];

  myDateArray[indexdd] = dd;
  myDateArray[indexmm] = mm;
  myDateArray[indexyyyy] = yyyy;

  return myDateArray.join(splitter);
};

export const getDateFormatByCountry = (country: string, timestamp: boolean | undefined = undefined) => {
  let format = '';
  switch (country) {
    case 'United States':
      format = 'MM/dd/yyyy';
      break;
    case 'China':
    case 'Taiwan':
    case `Korea, Democratic People's Republic of`:
    case 'Korea, Republic of':
    case 'Japan':
    case 'Canada':
      format = 'yyyy/MM/dd';
      break;
    default:
      format = 'dd/MM/yyyy';
      break;
  }
  return timestamp ? format + ' HH:mm' : format;
};

export const stringDateFromAPIFormatter = (date: string | undefined) => {
  if (date) {
    return date.split('-').reverse().join('/');
  } else {
    return '';
  }
};

export const isFutureDate = (date: string, country: string, customDateFormat?: string) => {
  const today = newDateFormat(new Date(), country, customDateFormat?.toLowerCase());
  const format = customDateFormat ?? getDateFormatByCountry(country);

  const allSpecialChars = /[ `!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]/g;
  const splitter = format?.match(allSpecialChars)?.[0] ?? '';

  const ddIndex = String(format).toLowerCase().split(splitter).indexOf('dd');
  const mmIndex = String(format).toLowerCase().split(splitter).indexOf('mm');
  const yyyyIndex = String(format).toLowerCase().split(splitter).indexOf('yyyy');

  const dd: string = date.split(splitter)[ddIndex],
    mm: string = date.split(splitter)[mmIndex],
    yyyy: string = date.split(splitter)[yyyyIndex];
  const tdd: string = today.split(splitter)[ddIndex],
    tmm: string = today.split(splitter)[mmIndex],
    tyyyy: string = today.split(splitter)[yyyyIndex];

  if (yyyy && tyyyy) {
    if (yyyy > tyyyy) {
      return true;
    }
    if (yyyy < tyyyy) {
      return false;
    }
  }

  if (mm && tmm) {
    if (mm > tmm) {
      return true;
    }
    if (mm < tmm) {
      return false;
    }
  }

  if (dd && tdd) {
    if (dd > tdd) {
      return true;
    }
  }

  return false;
};

export function isDateValid(date: string, format: string) {
  if (!date || !format) {
    return false;
  }

  const allSpecialChars = /[ `!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]/g;
  const splitter = format?.match(allSpecialChars)?.[0] ?? '';

  const ddIndex = String(format).toLowerCase().split(splitter).indexOf('dd');
  const mmIndex = String(format).toLowerCase().split(splitter).indexOf('mm');
  const yyyyIndex = String(format).toLowerCase().split(splitter).indexOf('yyyy');

  const dateArray = date.split(splitter);
  const dd: string = dateArray[ddIndex],
    mm: string = dateArray[mmIndex],
    yyyy: string = dateArray[yyyyIndex];

  if ((dd && dd.length !== 2) || (mm && mm.length !== 2) || (yyyy && yyyy.length !== 4)) {
    return false;
  }

  function isLeapYear() {
    if (parseInt(yyyy, 10) % 4 === 0) {
      if (parseInt(yyyy, 10) % 100 === 0) {
        if (parseInt(yyyy, 10) % 400 === 0) {
          return true;
        }
      } else {
        return true;
      }
    }
    return false;
  }

  if ((dd && (parseInt(dd) < 1 || parseInt(dd) > 31)) || (mm && (parseInt(mm) > 12 || parseInt(mm) < 1))) {
    return false;
  }

  if (dd && dd === '31') {
    return ['01', '03', '05', '07', '08', '10', '12'].includes(mm);
  }

  if (mm && mm === '02') {
    if (isLeapYear()) {
      return !dd || (dd && parseInt(dd) <= 29);
    } else {
      return !dd || (dd && parseInt(dd) <= 28);
    }
  }
  return true;
}

// Date to YYYY-MM-DD HH:mm:ss
export const timestampFormatter = (date: Date) => {
  return (
    [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join('-') +
    ' ' +
    [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes()), padTo2Digits(date.getSeconds())].join(':')
  );
};

const padTo2Digits = (num: any) => {
  return num.toString().padStart(2, '0');
};

export const textDecode = (text: string) => (text ? decodeURIComponent(atob(text)) : text);
export const textEncode = (text: string) => (text ? btoa(encodeURIComponent(text)) : text);
export function transformOtherTextWCP(obj: { [index: string]: any }, operation: TextOperation.ENCODE | TextOperation.DECODE) {
  // Clone the object to avoid modifying the original
  const newObject = { ...obj };

  // Iterate through the object
  for (const section in newObject) {
    if (newObject.hasOwnProperty(section)) {
      const sectionData = newObject[section];

      // Check if the category has "OTHER_TEXT" property
      if (sectionData.hasOwnProperty(WCPRecordType.OTHER_TEXT)) {
        // Apply the textDecode/textEncode function to the "OTHER_TEXT" value
        sectionData[WCPRecordType.OTHER_TEXT] =
          operation === TextOperation.ENCODE ? textEncode(sectionData.OTHER_TEXT) : textDecode(sectionData.OTHER_TEXT);
      }
    }
  }

  return newObject;
}

export const isSixDigitNumber = (input: string): boolean => {
  const sixDigitRegex = /^\d{6}$/;
  return sixDigitRegex.test(input);
};

export const generateToken = (length: number) => {
  const array = new Uint8Array(length);
  crypto.getRandomValues(array);

  return Array.from(array, byte => byte.toString(36))
    .join('')
    .slice(0, length);
};

export const customWoundSortByLockStatus = (wound1: Wound, wound2: Wound) => {
  if (wound1.is_active === WoundStatus.LOCKED && wound2.is_active === WoundStatus.UNLOCKED) {
    return 1;
  }
  if (wound1.is_active === WoundStatus.UNLOCKED && wound2.is_active === WoundStatus.LOCKED) {
    return -1;
  }
  return 0;
};
export const customWoundSortByPosition = (wound1: Wound, wound2: Wound) => {
  return Number(wound2.position) - Number(wound1.position);
};

export const handleSort = (property: string, order: string, sortedData: any) => {
  const sorted = [...sortedData];
  sorted.sort((a, b) => {
    switch (property) {
      case 'isActive':
        const valueA = a.status as any;
        const valueB = b.status as any;
        if (valueA > valueB) {
          return order === 'asc' ? 1 : -1;
        } else if (valueA < valueB) {
          return order === 'asc' ? -1 : 1;
        }
        break;

      default:
        const stringValueA = (a as any)[property]?.toString().toLowerCase();
        const stringValueB = (b as any)[property]?.toString().toLowerCase();
        if (stringValueA > stringValueB) {
          return order === 'asc' ? 1 : -1;
        } else if (stringValueA < stringValueB) {
          return order === 'asc' ? -1 : 1;
        }
        break;
    }
    return 0;
  });

  return [...sorted];
};

export const searchObjects = (List: any[], searchTerm: string) => {
  return List.filter(obj =>
    Object?.values(obj).some(value => typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())),
  );
};

export const differenceInDays = (date1: Date, date2: Date): number => {
  const oneDay = 1000 * 60 * 60 * 24; // milliseconds in a day
  const diffInMs = Math.abs(date2.getTime() - date1.getTime());
  return Math.floor(diffInMs / oneDay);
};

export const isEmailValid = (email: string): boolean => {
  return contactEmailPattern.test(email);
};

export const isPhoneValid = (phone: string): boolean => {
  return contactPhonePattern.test(phone);
};

export const isWebValid = (web: string) => {
  return webPatternV2.test(web);
};

export const isAlphanumeric = (input: string): boolean => {
  const inputWithoutSpaces = input.replace(/\s+/g, '');
  return alphanumericValidRegex.test(inputWithoutSpaces);
};

export const exportFormatDate = (date: { getFullYear: () => any; getMonth: () => number; getDate: () => any }) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export enum ReportContent {
  Measurements = 'measurements',
  Assessments = 'assessments',
  PatientAssessments = 'patientAssessments',
  Treatments = 'treatments',
  Images = 'images',
  RiskFactors = 'riskFactors',
  GeneralAssessment = 'generalAssessment',
  PatientCensus = 'patientCensus',
  WoundCensus = 'woundCensus',
  HqImages = 'hqImages',
  Insurances = 'insurances',
}

export const fullName = (fn: string, ln: string) => {
  return `${fn} ${ln}`;
};

export const isValidPassword = (password: string): boolean => {
  const hasMinLength = password.length >= 8;
  const hasDigit = /[0-9]/.test(password);
  const hasLowercase = /[a-z]/.test(password);
  const hasUppercase = /[A-Z]/.test(password);
  const hasSymbol = /[^A-Za-z0-9]/.test(password);

  return hasMinLength && hasDigit && hasLowercase && hasUppercase && hasSymbol;
};

export const isObjectEmpty = (objectName: any) => {
  return objectName && Object.keys(objectName).length === 0 && objectName.constructor === Object;
};

export const RemovePrefixes = (text: string) => {
  const arrayText = text.split(',');
  if (arrayText.length == 0) {
    return text;
  } else {
    const newText = arrayText.map(string => (string.indexOf('_') !== -1 ? string.split('_').slice(1).join('_') : string));
    return newText.join(', ');
  }
};

export const TextPolish = (text: string) => {
  return text.toString().replaceAll('__', '/').replaceAll('_', ' ').toLowerCase();
};

export const isSameDay = (dateTime1: string, dateTime2: string) => {
  const date1 = dateTime1.split(' ')[0];
  const date2 = dateTime2.split(' ')[0];
  const [year1, month1, day1] = date1.split('-').map(Number);
  const [year2, month2, day2] = date2.split('-').map(Number);

  return year1 === year2 && month1 === month2 && day1 === day2;
};

export const DateToUTC = (date: Date) => {
  const utcYear = date.getUTCFullYear();
  const utcMonth = date.getUTCMonth();
  const utcDay = date.getUTCDate();
  const utcHours = date.getUTCHours();
  const utcMinutes = date.getUTCMinutes();
  const utcSeconds = date.getUTCSeconds();

  const utcDate = new Date(Date.UTC(utcYear, utcMonth, utcDay, utcHours, utcMinutes, utcSeconds));

  return utcDate;
};

export const customizeTranslation = async (siteId: string | undefined, accountId: string | undefined, groupId: string | undefined) => {
  const translation = await FMService.getTranslation(siteId, accountId, groupId);
  i18nCustom.customize(translation);
};

export function toggleZendeskWidget(show: boolean) {
  const zendeskIframe = document.getElementById('launcher');
  if (zendeskIframe) {
    zendeskIframe.style.visibility = show ? 'visible' : 'hidden';
  }
}

export const sortFieldValues = (array: Field[]) => {
  array.map(item => {
    item.field_values.sort((a, b) => {
      if (a.value === 'Other') return 1;
      if (b.value === 'Other') return -1;
      return a.value.localeCompare(b.value);
    });
  });
  return array;
};

interface ErrorMessages {
  [key: string]: string[];
}

export const formatErrorMessages = (messages: string | ErrorMessages) => {
  if (typeof messages === 'string') {
    return messages;
  } else {
    let formattedString = '';
    for (const key in messages) {
      if (messages.hasOwnProperty(key)) {
        const valueArray = messages[key];
        const formattedValue = valueArray.join('\n');
        formattedString += `${formattedValue}\n`;
      }
    }
    return formattedString;
  }
};

export const isStandardMode = (appMode: AppMode) => {
  return appMode === AppModes.Standard;
};

export const isContextSwitchHomeMode = (appMode: AppMode) => {
  return appMode === AppModes.ContextSwitchHome;
};

export const isContextSwitchWoundMode = (appMode: AppMode) => {
  return appMode === AppModes.ContextSwitchWound;
};

export const getInstanceClassName = (instance: AppInstance): string => {
  switch (instance) {
    case AppInstance.CR:
      return 'login-cr';
    case AppInstance.NATROX:
      return 'login-natrox';
    default:
      return 'login-default';
  }
};

export enum AdminContent {
  MoveData = 'moveData',
  ManageData = 'manageData',
  ManagePatients = 'ManagePatients',
  ManageMeasurements = 'ManageMeasurements',
  RestoreData = 'restoreData',
  ManageWounds = 'ManageWounds',
  ManageLogs = 'manageLogs',
  ManageAssessments = 'ManageAssessments',
  FMConsole = 'fmConsole',
}
