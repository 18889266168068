import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Patient } from 'src/core/models/patient.models';
import PatientContext from 'src/core/context/patient/PatientContext';
import Table from 'src/components/table/Table.component';
import { ColParam, TableActions } from 'src/components/table/table.models';
import { IoCheckmarkCircle, IoCloseCircle } from 'react-icons/io5';
import { MdPhoneIphone } from 'react-icons/md';
//import { getPatientAge, getPatientFullLocation } from '../patients.utils';
import { can, getUserRole, handleSort, hideFeature } from 'src/core/utils/global.utils';
import AppContext from 'src/core/context/global/AppContext';
import { useNavigate } from 'react-router-dom';
import { User } from 'src/core/models/user.models';
import { colors } from 'src/assets/styles/theme.style';
import { use } from 'i18next';
import { getSystemRoleLabelKey } from 'src/pages/users/users.utils';
import { TableActionsOptions } from 'src/core/models/group.models';

interface ComponentProps {
  users: User[];
  isLoading?: boolean;
  getUsers: () => void;
  onEditUser: (users: any) => void;
  onInviteUser: (users: User[]) => void;
  onDeleteUsers: (users: User[]) => void;
}

const GroupUsersList = (props: ComponentProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user, structure, abilities } = useContext(AppContext);
  const [sortedData, setSortedData] = useState<any[]>([]);
  const [showItems, setShowItems] = useState<boolean>(false);

  const tableColumns: ColParam[] = [
    {
      key: 'access_enabled',
      label: <MdPhoneIphone size='18' />,
    },
    {
      key: 'last_name',
      label: t('userManagement.lastName'),
      fontWeight: '500',
    },
    {
      key: 'first_name',
      label: t('userManagement.firstName'),
      fontWeight: '500',
    },
    {
      key: 'username',
      label: t('user.userName'),
    },
    {
      key: 'email',
      label: t('userManagement.email'),
    },
    {
      key: 'system_role',
      label: t('userManagement.systemRole'),
    },
    {
      key: 'clinical_role',
      label: t('userManagement.clinicalRole'),
    },
    {
      key: 'isActive',
      label: t('common.status'),
    },
  ];

  const tableData = props.users.map(u => {
    return {
      ...u,
      system_role: t(getSystemRoleLabelKey(u.system_role, structure.type)),
      access_enabled: <></>,
      isActive:
        u.status === '1' ? (
          <IoCheckmarkCircle size='18' color={colors.statusActive} />
        ) : (
          <IoCloseCircle size='18' color={colors.statusNotActive} />
        ),
    };
  });
  useEffect(() => {
    setSortedData(tableData);
  }, [props.users, t]);

  const handleRequestSort = (property: string, order: string): void => {
    const sorted = handleSort(property, order, sortedData);
    setSortedData(sorted);
  };

  const tableActions: TableActions = {
    groupActions: [
      { id: TableActionsOptions.Edit, label: t('common.edit'), onClick: props.onEditUser },
      { id: TableActionsOptions.Delete, label: t('common.delete'), onClick: props.onDeleteUsers },
    ],
    singleActions: (user: User) => {
      return [
        { id: 'edit', icon: 'edit', onClick: props.onEditUser },
        { id: 'delete', icon: 'delete', onClick: props.onDeleteUsers },
        {
          id: 'more',
          icon: 'more',
          dropdown: true,
          dropdownItems: [
            {
              id: 'resendInvitation',
              label: t('user.resendInvitation'),
              onClick: props.onInviteUser,
            },
          ],
        },
      ];
    },
  };

  const isActionAllowed = (action: { id: string }, row: any) => {
    const { id } = action;

    switch (id) {
      case 'delete':
        return !can(structure, ['update_user'], abilities);
      case 'edit':
        return !can(structure, ['update_user'], abilities);
      default:
        return false;
    }
  };

  const isMenuItemAllowed = (menuItem: { id: string }, row: any) => {
    const { id } = menuItem;

    switch (id) {
      case 'resendInvitation':
        return can(getUserRole(user, structure), ['add_user'], abilities) && row.email_confirmed === '0';

      default:
        return true;
    }
  };

  return (
    <div className='users-list'>
      <Table
        columns={tableColumns}
        data={sortedData}
        actions={tableActions}
        multiSelect={false}
        isLoading={props.isLoading}
        onRequestSort={handleRequestSort}
        isActionAllowed={isActionAllowed}
        isMenuItemAllowed={isMenuItemAllowed}
        showMenuItem={false}
      />
    </div>
  );
};

export default GroupUsersList;
