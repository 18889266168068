import './sideMenu.style.scss';
import { useTranslation } from 'react-i18next';
import { can } from 'src/core/utils/global.utils';
import { useContext } from 'react';
import AppContext from 'src/core/context/global/AppContext';
import { Typography } from '@mui/material';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';

interface Props {
  openPage: any;
  signOut: any;
  getItemClassName: any;
}

function AdminMenu(props: Props) {
  const { openPage, signOut, getItemClassName } = props;
  const { t } = useTranslation();
  const { structure, abilities } = useContext(AppContext);

  return (
    <div className='side-menu'>
      <ul className='items'>
        <li className={getItemClassName('/groups')} onClick={() => openPage('/groups')}>
          <HomeOutlinedIcon className='item-icon' />
          <Typography noWrap>{t('menu.groups')}</Typography>
        </li>
        <li className={getItemClassName('/adminNotifications')} onClick={() => openPage('/adminNotifications')}>
          <NotificationsOutlinedIcon className='item-icon' />
          <Typography noWrap>{t('menu.notifications')}</Typography>
        </li>
        {!can(structure, ['export_report'], abilities) && (
          <li
            className={getItemClassName('/activeUsers')}
            onClick={() => {
              openPage('/activeUsers');
            }}
          >
            <AssignmentOutlinedIcon className='item-icon' />
            <Typography noWrap>{t('menu.activeUsers')}</Typography>
          </li>
        )}
        <div className='bottom-items'>
          <li key={'signOut'} className='item' onClick={signOut}>
            <LogoutOutlinedIcon className='item-icon' />
            <Typography noWrap>{t('menu.signOut')}</Typography>
          </li>
        </div>
      </ul>
    </div>
  );
}

export default AdminMenu;
