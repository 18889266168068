import { instance } from 'src/config';
import { AppInstance } from 'src/core/utils/instance.utils';
import './theme.style.scss';

const defaultColors = {
  primaryColor: '#38bbcc',
  primaryText: '#12203E',
  bodyBackground: '#f2f5fa',
  statusActive: '#0AE591',
  statusNotActive: '#FF6485',
  search: '#7786A4',
  activeShadowColor: 'rgba(88, 213, 205, 0.5)',

  menu_about_label: '#12203e',
  menu_about_text: '#5a6171',
  menu_about_subPage: '#fff',
  menu_about_gridBorder: '#eaeff4',

  sideContainer_backIcon: 'rgba(58, 187, 204, 0.2)',

  wounds_patientDetails_label: '#5A6171',
  wounds_patientDetails_value: '#12203E',

  wounds_woundsList_label: '#12203E',
  wounds_woundsList_woundLocation: '#5A6171',
  wounds_woundsList_index: '#7786A4',
  wounds_woundsList_woundType: '#3ABBCC',
  wounds_woundsList_woundBtnBorder: '#ECEFF5',
  wounds_woundsList_woundBtnActive: 'rgba(58, 187, 204, 0.15)',
  wounds_treatment_filledWCP: '#3abbcc',

  sideMenuBackground: '#ffffff',
  woundSideMenuBackground: '#ffffff',
  homePageBackground: ' #f2f5fa',
  sideMenuText: '#7786a4',

  tableHeaderBg: '#ffffff',
  tableHeaderText: '#0c1015',
  tabTextColor: '#12203e',
};

const crColors = {
  ...defaultColors,
  primaryColor: '#bf87ed',
  primaryText: '#333333',
  bodyBackground: '#ffe6e6',
  activeShadowColor: 'rgba(191, 135, 237, 0.15)',
  sideContainer_backIcon: 'rgba(191, 135, 237, 0.15)',
};

const natroxColors = {
  ...defaultColors,
  primaryColor: 'rgb(55,114,180)',
  primaryText: '#333333',
  bodyBackground: '#f9f9f9',
  activeShadowColor: 'rgba(55,114,180, 0.5)',
  sideContainer_backIcon: 'rgba(55,114,180, 0.2)',

  sideMenuBackground: 'linear-gradient(rgb(111,47,97), rgb(20,132,203))',
  woundSideMenuBackground: '#ffffff',
  sideMenuText: '#ffffff',
  homePageBackground: '#f1eaef',

  tableHeaderBg: 'rgb(55,114,180)',
  tableHeaderText: '#fff',
  tabTextColor: 'rgb(111, 47, 97)',
};

export const getColors = () => {
  switch (instance) {
    case AppInstance.CR:
      return crColors;
    case AppInstance.NATROX:
      return natroxColors;
    default:
      return defaultColors;
  }
};

export const colors = getColors();

const root = document.documentElement;
root.style.setProperty('--background-color', colors.homePageBackground);
root.style.setProperty('--primary-text', colors.primaryText);
