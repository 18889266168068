import { use } from 'i18next';
import { DateFormatCountryMapping } from '../utils/global.utils';
import { Patient } from './patient.models';
import { OwnSubscription } from '../services/about.service';

export class Config {
  app?: string;
  awsConfig?: {
    endpoint?: string;
    awsS3bucketName?: string;
    awsS3bucketRegion?: string;
  };
  oauth2?: {
    enabled?: boolean;
  };
  sisenseConfig?: boolean;
  parseServer?: {
    enabled?: boolean;
  };

  ldapAuth?: boolean;
  ldapDomains?: string[];
  HQImagesUsers?: string[];
  canExportInsurance?: number[];

  constructor(config?: any) {
    if (config) {
      this.app = config.app;
      this.awsConfig = config.awsConfig;
      this.oauth2 = config.oauth2;
      this.parseServer = config.parseServer;
      this.sisenseConfig = config.sisenseConfig;
      this.ldapAuth = config.ldapAuth;
      this.ldapDomains = config.ldapDomains;
      this.HQImagesUsers = config.HQImagesUsers;
      this.canExportInsurance = config.canExportInsurance;
    }
  }
}

export class Structure {
  id: number;
  class_id?: string;
  account_number?: string;
  name?: string;
  web?: string;
  type?: StructureType;
  address?: string;
  country = '';
  state_province?: string;
  city?: string;
  contact_name?: string;
  contact_phone?: string;
  contact_email?: string;
  setting?: any;
  purpose?: any;
  status?: '1' | '0';
  deleted?: string;
  parent_id?: number;
  parent?: Structure;
  photos_setting?: string;
  data_access?: string;
  bi_access?: string;
  logo_link?: string;
  external_id?: string;
  healthcare_id?: any;
  id_supplier?: any;
  passwords_expiration_interval?: number;
  account_wide_access?: string;
  require_mfa?: string;
  mfa_methods?: string;
  created_at?: string;
  role?: string;
  ownSubscription?: OwnSubscription[];
  has_visit_name?: boolean;
  user_has_bi_access?: boolean;
  action_setting: string | undefined;
  clinical_role?: string;
  structure_status?: '1' | '0';
  ownUser_manage_structure?: OwnUserManageStructure[];
  healbot_enabled?: boolean;

  constructor(structure?: any) {
    this.id = structure && structure.id ? +structure.id : 0;
    if (structure) {
      this.class_id = structure.class_id;
      this.account_number = structure.account_number;
      this.name = structure.name;
      this.web = structure.web;
      this.type = structure.type;
      this.address = structure.address;
      this.country = structure.country;
      this.state_province = structure.state_province;
      this.city = structure.city;
      this.contact_name = structure.contact_name;
      this.contact_phone = structure.contact_phone;
      this.contact_email = structure.contact_email;
      this.setting = structure.setting;
      this.purpose = structure.purpose;
      this.status = structure.status;
      this.deleted = structure.deleted;
      this.parent_id = structure.parent_id;
      this.parent = structure.parent;
      this.photos_setting = structure.photos_setting;
      this.data_access = structure.data_access;
      this.bi_access = structure.bi_access;
      this.logo_link = structure.logo_link;
      this.external_id = structure.external_id;
      this.healthcare_id = structure.healthcare_id;
      this.id_supplier = structure.id_supplier;
      this.passwords_expiration_interval = structure.passwords_expiration_interval;
      this.account_wide_access = structure.account_wide_access;
      this.require_mfa = structure.require_mfa;
      this.mfa_methods = structure.mfa_methods;
      this.created_at = structure.created_at;
      this.role = structure.role;
      this.ownSubscription = structure.ownSubscription;
      this.has_visit_name = Boolean(structure.has_visit_name);
      this.user_has_bi_access = Boolean(structure.user_has_bi_access);
      this.action_setting = '';
      this.clinical_role = structure.clinical_role;
      this.structure_status = structure.status;
      this.ownUser_manage_structure = structure.ownUser_manage_structure;
      this.healbot_enabled = Boolean(structure.healbot_enabled);
    }
  }

  getDateFormat(): string {
    if (this.country && DateFormatCountryMapping[this.country] != undefined) {
      return DateFormatCountryMapping[this.country];
    } else {
      if (this.type == 'group') {
        return 'MM/DD/YYYY';
      } else {
        return 'DD/MM/YYYY';
      }
    }
  }
}

export interface AppIntegration {
  wound_id: string;
  patient: Patient;
  structure: Structure;
}

export class User {
  id: number;
  username?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  phone?: string;
  p?: string;
  password_date?: string;
  external_id?: number;
  id_patient?: number;
  clinical_role?: string;
  role?: string;
  has_bi_access?: boolean;
  home_structure?: number;
  sensor_setting?: string;
  access_token?: string;
  api_key?: string;
  status?: string;
  structures?: Structure[];
  mfa_method?: string;
  mfa_status?: string;
  pw_expiration_days_left?: string;
  confirm_email: User | undefined;
  searchPatientByButton?: boolean;
  app_integration: AppIntegration | undefined;
  integrations?: Integrations;

  constructor(user?: any) {
    this.id = user && user.id ? +user.id : 0;
    if (user) {
      this.username = user.username;
      this.first_name = user.first_name;
      this.last_name = user.last_name;
      this.email = user.email;
      this.phone = user.phone;
      this.p = user.p;
      this.password_date = user.password_date;
      this.external_id = user.external_id;
      this.id_patient = user.id_patient;
      this.clinical_role = user.clinical_role;
      this.role = user.role;
      this.has_bi_access = user.has_bi_access;
      this.home_structure = user.home_structure;
      this.sensor_setting = user.sensor_setting;
      this.access_token = user.access_token;
      this.api_key = user.api_key;
      this.status = user.status;
      this.structures = user.structures;
      this.mfa_method = user.mfa_method;
      this.mfa_status = user.mfa_status;
      this.pw_expiration_days_left = user.pw_expiration_days_left;
      this.searchPatientByButton = user.searchPatientByButton;
      this.app_integration = user.app_integration;
      this.integrations = user.integrations;
    }
  }

  isSiteLevel = () => {
    return this.structures && this.structures.length > 0 && this.structures[0].type == 'site';
  };

  isAccountLevel = () => {
    return this.structures && this.structures.length > 0 && this.structures[0].type == 'account';
  };

  isGroupLevel = () => {
    return this.structures && this.structures.length > 0 && this.structures[0].type == 'group';
  };

  isEkareAdmin = () => {
    return this.role && this.role == 'ekare_admin';
  };

  getLevel = () => {
    if (this.isSiteLevel()) {
      return 'site_level';
    } else if (this.isAccountLevel()) {
      return 'account_level';
    } else if (this.isGroupLevel()) {
      return 'group_level';
    } else if (this.isEkareAdmin()) {
      return 'ekare_admin';
    } else {
      return 'unknown';
    }
  };

  isAuthenticated = () => {
    if (this.isEkareAdmin()) {
      return this.api_key;
    } else {
      return this.access_token && this.api_key;
    }
  };
}

export interface OwnUserManageStructure {
  id?: string;
  structure_id?: string;
  user_id: string | number;
  role?: string;
  status?: string;
}

export enum AppModes {
  Standard = 'standard',
  ContextSwitchHome = 'contextSwitchHome',
  ContextSwitchWound = 'contextSwitchWound',
}

export interface Integrations {
  is_emis_enabled: boolean;
}

export type AppMode = AppModes.Standard | AppModes.ContextSwitchHome | AppModes.ContextSwitchWound;

export enum StructureType {
  Site = 'site',
  Account = 'account',
  Group = 'group',
}

export type StructureTypes = StructureType.Site | StructureType.Account | StructureType.Group | undefined;
