import { instance } from 'src/config';
import { AppInstance } from 'src/core/utils/instance.utils';

// White logos
import defaultLogo from 'src/assets/images/insight-healthcare-white.png';
import crLogo from 'src/assets/images/Insight-Research-white.png';
import natroxLogo from 'src/assets/images/natrox-white.png';
import singhealthLogo from 'src/assets/images/insight-healthcare-white.png';
import euLogo from 'src/assets/images/Insight-Europe-white.png';
import ukLogo from 'src/assets/images/Insight-Europe-white.png';
import sgLogo from 'src/assets/images/insight-healthcare-white.png';

// Simple logos
import defaultLogoSimple from 'src/assets/images/insight-helathcare.png';
import crLogoSimple from 'src/assets/images/Insight-Research.png';
import natroxLogoSimple from 'src/assets/images/natrox.png';
import singhealthLogoSimple from 'src/assets/images/insight-helathcare.png';
import euLogoSimple from 'src/assets/images/Insight-Europe.png';
import ukLogoSimple from 'src/assets/images/Insight-Europe.png';
import sgLogoSimple from 'src/assets/images/insight-helathcare.png';

export const useInstanceLogo = (isWhiteVersion?: boolean) => {
  switch (instance) {
    case AppInstance.CR:
      return !isWhiteVersion ? crLogo : crLogoSimple;
    case AppInstance.NATROX:
      return !isWhiteVersion ? natroxLogo : natroxLogoSimple;
    case AppInstance.EU:
      return !isWhiteVersion ? euLogo : euLogoSimple;
    case AppInstance.UK:
      return !isWhiteVersion ? ukLogo : ukLogoSimple;
    case AppInstance.SG:
      return !isWhiteVersion ? sgLogo : sgLogoSimple;
    case AppInstance.SINGHEALTH:
      return !isWhiteVersion ? singhealthLogo : singhealthLogoSimple;
    default:
      return !isWhiteVersion ? defaultLogo : defaultLogoSimple;
  }
};
