import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import MUITableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { ColParam, TableActions } from '../table.models';
import Button from '@mui/material/Button';
import { FiEdit, FiMoreHorizontal } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AppContext from 'src/core/context/global/AppContext';
import { FaUndo, FaUsers } from 'react-icons/fa';
import { Select } from '@mui/material';
import { DropItemsProps } from 'src/pages/sites/components/UserManagement.component';
import { HiOutlineSwitchHorizontal } from 'react-icons/hi';

interface Props {
  columns: ColParam[];
  multiSelect: boolean;
  data: any[];
  actions?: TableActions;
  selectedRows?: any[];
  dense?: boolean;
  rowCount?: number;
  showMenuItem?: boolean;
  singleSelect?: boolean;
  onSelectRow: (event: ChangeEvent<HTMLInputElement>, row: any) => void;
  onRowClick?: (row: any) => void;
  isActionAllowed?: (action: { id: string }, row: { is_enabled: string }) => any;
  isMenuItemAllowed?: (menuItem: { id: string }, row: { is_enabled: string }) => any;
  isSelected?: (id: number) => boolean;
  dropdownItems?: DropItemsProps[];
  isDropdownAllowed?: boolean;
  onDropdownChange?: (row: any) => void;
}

function TableBody(props: Props) {
  const { structure, abilities } = useContext(AppContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorGroupMenu, setAnchorGroupMenu] = React.useState<any>(null);

  // Avoid a layout jump when reaching the last page with empty data.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.data.length) : 0;

  const getActionIcon = (iconName: string) => {
    switch (iconName) {
      case 'more':
        return <FiMoreHorizontal />;
      case 'edit':
        return <FiEdit />;
      case 'delete':
        return <RiDeleteBinLine />;
      case 'users':
        return <FaUsers />;
      case 'restore':
        return <FaUndo />;
      case 'move':
        return <HiOutlineSwitchHorizontal />;
      default:
        return <></>;
    }
  };

  const handleOpenActionMenu = (key: any, event: any) => {
    setAnchorGroupMenu({ [key]: event.currentTarget });
  };

  const handleCloseActionMenu = () => {
    setAnchorGroupMenu(null);
  };

  const handleRowSelection = (event: ChangeEvent<HTMLInputElement>, row: any) => {
    if (props.singleSelect) {
      // If singleSelect mode, only one row can be selected at a time
      // setSelectedRowId(row.id);
      props.onSelectRow(event, []);
    } else {
      // Use the provided onSelectRow for multi-select
      props.onSelectRow(event, row);
    }
  };

  return (
    <MUITableBody>
      {props.data.map((row, rowIndex) => {
        const isRowSelected = props.isSelected && props.isSelected(row.id);
        const rowActions = props.actions && props.actions.singleActions(row);

        return (
          <TableRow
            key={rowIndex}
            role='checkbox'
            aria-checked={isRowSelected}
            tabIndex={-1}
            selected={isRowSelected}
            hover={props.onRowClick != undefined}
          >
            {/* {props.multiSelect && (
              <TableCell padding='checkbox'>
                <Checkbox color='primary' checked={isRowSelected} onChange={e => props.onSelectRow(e, row)} />
              </TableCell>
            )} */}
            {(props.multiSelect || props.singleSelect) && (
              <TableCell padding='checkbox'>
                <Checkbox color='primary' checked={isRowSelected} onChange={e => handleRowSelection(e, row)} />
              </TableCell>
            )}

            {props.columns.map(colParam => (
              <TableCell
                style={
                  row?.is_enabled === '0'
                    ? { fontWeight: colParam.fontWeight || '400', opacity: 0.2 }
                    : { fontWeight: colParam.fontWeight || '400' }
                }
                onClick={() => {
                  props.onRowClick && props.onRowClick(row);
                }}
                key={colParam.key}
              >
                {row[colParam.key]}
              </TableCell>
            ))}

            {props.actions && (
              <TableCell key={'actions'} align={'right'}>
                {rowActions?.map((act: any, actionIndex) =>
                  act.dropdown ? (
                    <>
                      {!props.showMenuItem ? (
                        <Button
                          key={actionIndex}
                          color='secondary'
                          className='action-button'
                          variant='contained'
                          size='small'
                          disableElevation
                          // aria-controls={openGroupMenu ? 'action-menu' : undefined}
                          aria-haspopup='true'
                          // aria-expanded={openGroupMenu ? 'true' : undefined}
                          onClick={e => handleOpenActionMenu(actionIndex + '' + rowIndex, e)}
                        >
                          {getActionIcon(act.icon)}
                        </Button>
                      ) : (
                        <></>
                      )}

                      <Menu
                        className='action-menu'
                        anchorEl={anchorGroupMenu && anchorGroupMenu[actionIndex + '' + rowIndex]}
                        open={anchorGroupMenu && Boolean(anchorGroupMenu[actionIndex + '' + rowIndex])}
                        onClose={handleCloseActionMenu}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                      >
                        {act.dropdownItems?.map((menuItem: any, menuItemIndex: any) =>
                          props.isMenuItemAllowed && props.isMenuItemAllowed(menuItem, row) ? (
                            <MenuItem
                              key={menuItemIndex}
                              onClick={() => {
                                menuItem.onClick([row]);
                                setAnchorGroupMenu(null);
                              }}
                            >
                              {menuItem.label}
                            </MenuItem>
                          ) : null,
                        )}
                      </Menu>
                    </>
                  ) : props.isActionAllowed && props.isActionAllowed(act, row) ? (
                    <Button
                      key={actionIndex}
                      color='secondary'
                      className='action-button'
                      variant='contained'
                      size='small'
                      disableElevation
                      onClick={() => {
                        act.onClick([row]);
                      }}
                    >
                      {getActionIcon(act.icon)}
                    </Button>
                  ) : (
                    <></>
                  ),
                )}
              </TableCell>
            )}
            {props.isDropdownAllowed && (
              <TableCell>
                {isRowSelected && (
                  <Select
                    size='small'
                    fullWidth
                    value={row.system_role}
                    onChange={event => {
                      const updatedData = props.data.map(rowData =>
                        rowData.id === row.id ? { ...rowData, system_role: event.target.value } : rowData,
                      );
                      props.onDropdownChange && props.onDropdownChange(updatedData);
                    }}
                  >
                    {props.dropdownItems &&
                      props.dropdownItems.map((item, index) => (
                        <MenuItem key={index} value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              </TableCell>
            )}
          </TableRow>
        );
      })}
      {emptyRows > 0 && (
        <TableRow
          style={{
            height: (props.dense ? 33 : 53) * emptyRows,
          }}
        >
          <TableCell colSpan={6} />
        </TableRow>
      )}
    </MUITableBody>
  );
}

export default TableBody;
