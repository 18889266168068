import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import merge from 'lodash/merge'; // Ensure lodash's merge function is imported

import translationEN from './en';
import translationDE from './de';
import translationHE from './he';
import translationJP from './jp';
import translationKR from './kr';
import translationMS from './ms';
import translationNL from './nl';
import translationPT from './pt';
import translationSP from './sp';
import translationTA from './ta';
import translationTR from './tr';
import translationZH from './zh';

interface TranslationResource {
  [key: string]: {
    translation: any;
  };
}

// the translations
const resources: TranslationResource = {
  en: { translation: translationEN },
  de: { translation: translationDE },
  he: { translation: translationHE },
  jp: { translation: translationJP },
  kr: { translation: translationKR },
  ms: { translation: translationMS },
  nl: { translation: translationNL },
  pt: { translation: translationPT },
  sp: { translation: translationSP },
  ta: { translation: translationTA },
  tr: { translation: translationTR },
  zh: { translation: translationZH },
};

const DETECTION_OPTIONS = {
  order: ['localStorage', 'navigator'],
  caches: ['localStorage'],
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: resources,
    detection: DETECTION_OPTIONS,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

const customize = (translations: TranslationResource): void => {
  Object.keys(translations).forEach(lang => {
    if (resources[lang]) {
      resources[lang].translation = merge(resources[lang].translation, translations[lang].translation);
    } else {
      resources[lang] = { translation: translations[lang].translation };
    }

    // Update i18n resources
    i18n.addResourceBundle(lang, 'translation', resources[lang].translation, true, true);
  });
};

export default {
  t: i18n.t.bind(i18n),
  setLocale: i18n.changeLanguage.bind(i18n),
  customize,
};
