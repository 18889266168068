import React, { useContext, useEffect, useState } from 'react';
import AppContext from 'src/core/context/global/AppContext';
import AuthService from 'src/core/services/auth.service';
import { auto_logout_delay } from 'src/config';
import SnackbarComponent from '../snackbar/Snackbar.component';
import { AlertColor } from '@mui/material';
import { useTranslation } from 'react-i18next';

const LogoutTimer = (props: { children: any }) => {
  const { children } = props;
  const { t } = useTranslation();
  const { user, clearSession } = useContext(AppContext);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: '' as AlertColor,
  });

  const closeSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar({
      open: false,
      severity: 'success',
      message: '',
    });
  };

  const signOut = async () => {
    try {
      await AuthService.signOut();
    } catch (error) {
      // No logs
    } finally {
      clearSession();
    }
  };
  useEffect(() => {
    if (user.id != 0) {
      const resetInactivityTimer = () => {
        clearTimeout(inactivityTimer as NodeJS.Timeout);
        inactivityTimer = setTimeout(() => {
          signOut();
          setSnackbar({
            open: true,
            severity: 'info',
            message: t('common.inactivityLogoutMsg'),
          });
        }, auto_logout_delay);
      };

      let inactivityTimer: NodeJS.Timeout;

      window.addEventListener('mousemove', resetInactivityTimer);
      window.addEventListener('keydown', resetInactivityTimer);

      resetInactivityTimer();

      return () => {
        window.removeEventListener('mousemove', resetInactivityTimer);
        window.removeEventListener('keydown', resetInactivityTimer);
      };
    }
  }, [user]);

  return (
    <>
      <SnackbarComponent open={snackbar.open} severity={snackbar.severity} message={snackbar.message} onClose={closeSnackbar} />
      {children}
    </>
  );
};

export default LogoutTimer;
