import ApiService from 'src/core/services/api.service';
import { ResetPasswordReq, SignInReq } from 'src/core/models/auth.models';
import md5 from 'crypto-js/md5';

export default class AuthService extends ApiService {
  public static getPreData(): Promise<any> {
    return this.apiGet('/auth/predata');
  }

  public static signIn(data: SignInReq): Promise<any> {
    return this.apiPost('/auth/signin', data);
  }

  public static signOut(): Promise<any> {
    return this.apiPost('/auth/signout');
  }
  public static ResetPassword(data: ResetPasswordReq): Promise<any> {
    return this.apiPost('/reset', data);
  }

  public static verifySession(): Promise<any> {
    return this.apiPost('/oauth2/verify');
  }
}
