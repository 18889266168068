import ApiService from 'src/core/services/api.service';
import { GetSchemaReq, PostSchemaReq } from '../models/fm.models';
import _ from 'lodash';

export default class FMService extends ApiService {
  public static async getTranslation(siteId: string | undefined, accountId: string | undefined, groupId: string | undefined): Promise<any> {
    let translation = {};
    const allTranslations = await this.apiGet('/customization/translation');
    if (allTranslations && allTranslations.length > 0) {
      // get translation by merging default < group < account < site
      // translations expected to be ordered by id_structure asc
      const userTranslations = allTranslations.filter((t: any) => {
        return ['0', groupId, accountId, siteId].includes(t.id_structure);
      });

      userTranslations.forEach((t: any) => {
        const part = JSON.parse(t.data);
        translation = _.merge(translation, part);
      });
    }

    return translation;
  }

  public static async getSchema(idStructure: GetSchemaReq['id_structure'], type: GetSchemaReq['type']) {
    const params: GetSchemaReq = {
      id_structure: idStructure,
      type,
    };
    try {
      const response = await this.apiGet('/form/schema', params);

      const mySchema = JSON.parse(response.schema);
      const properties = mySchema.definitions.Root.properties;
      const propertiesWithoutNull = Object.keys(properties).reduce((acc, cur) => {
        const currentProperty = properties[cur];
        const newTitle = currentProperty?.title ?? '';
        const newDescription = currentProperty?.description ?? '';

        return {
          ...acc,
          [cur]: {
            ...currentProperty,
            title: newTitle,
            description: newDescription,
          },
        };
      }, {});

      const newSchema = {
        ...mySchema,
        title: mySchema?.title ?? '',
        description: mySchema?.description ?? '',
        definitions: {
          ...mySchema.definitions,
          Root: {
            ...mySchema?.definitions?.Root,
            properties: {
              ...mySchema.definitions.Root.properties,
              ...propertiesWithoutNull,
            },
          },
        },
      };

      return { ...response, schema: JSON.stringify({ ...newSchema }) };
    } catch (error) {
      return null;
    }
  }
  public static async getAllSchema(idStructure: GetSchemaReq['id_structure']) {
    const params = {
      id_structure: idStructure,
    };
    try {
      return await this.apiGet('/form/schema', params);
    } catch (error) {
      return null;
    }
  }

  public static postSchema(
    idStructure: PostSchemaReq['id_structure'],
    type: PostSchemaReq['type'],
    schema: PostSchemaReq['schema'],
    uiSchema: PostSchemaReq['ui_schema'],
    data: PostSchemaReq['data'],
  ): Promise<any> {
    const body: PostSchemaReq = {
      id_structure: idStructure,
      type,
      schema,
      ui_schema: uiSchema,
      data: data,
    };
    return this.apiPost(`/form/schema`, body);
  }
}
