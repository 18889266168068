import React, { useContext, useEffect, useState } from 'react';
import AppContext from 'src/core/context/global/AppContext';
import AuthService from 'src/core/services/auth.service';
import { verify_session_interval } from 'src/config';
import SnackbarComponent from '../snackbar/Snackbar.component';
import { AlertColor } from '@mui/material';
import { useTranslation } from 'react-i18next';

const VerifySession = (props: { children: any }) => {
  const { children } = props;
  const { t } = useTranslation();
  const { user, clearSession } = useContext(AppContext);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: '' as AlertColor,
  });

  const closeSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar({
      open: false,
      severity: 'success',
      message: '',
    });
  };

  const signOut = async () => {
    try {
      await AuthService.signOut();
    } catch (error) {
      // No logs
    } finally {
      clearSession();
    }
  };

  const verifySession = async () => {
    try {
      if (user.id != 0) {
        await AuthService.verifySession();
      }
    } catch (error: any) {
      signOut();
      setSnackbar({
        open: true,
        severity: 'info',
        message: t('common.sessionExpiredMsg'),
      });
    }
  };

  useEffect(() => {
    const verifySessionInterval = setInterval(verifySession, verify_session_interval);
    return () => clearInterval(verifySessionInterval);
  }, [user]);

  return (
    <>
      <SnackbarComponent open={snackbar.open} severity={snackbar.severity} message={snackbar.message} onClose={closeSnackbar} />
      {children}
    </>
  );
};

export default VerifySession;
