import { ClinicalRoleOptions } from './account.models';

export interface AddGroupReq {
  class_id: string;
  clinical_role: string;
  confirm_pass: string;
  contact_email: string;
  contact_name: string;
  contact_phone: string;
  email: string;
  email2: string;
  first_name: string;
  last_name: string;
  name: string;
  password: string;
  phone: string;
  role: string;
  status: number;
  structure_status: number;
  username: string;
}

export interface UpdateGroupReq {
  class_id: string;
  name: string;
  contact_email: string;
  contact_name: string;
  contact_phone: string;
}

export enum GroupSystemRoleOptions {
  class_admin = 'class_admin',
  class_user = 'class_user',
}

export class GroupForm {
  [key: string]: any;
  id: string;
  action_setting: string;
  group_id: string;
  group_name: string;
  contact_name: string;
  email: string;
  phone: string;
  first_name: string;
  last_name: string;
  username: string;
  password: string;
  confirm_password: string;
  email2: string;
  confirm_email: string;
  phone2: string;
  system_role: GroupSystemRoleOptions;
  clinical_role: ClinicalRoleOptions;
  active: boolean;
  constructor() {
    this.id = '';
    this.action_setting = '';
    this.group_id = '';
    this.group_name = '';
    this.contact_name = '';
    this.email = '';
    this.phone = '';
    this.first_name = '';
    this.last_name = '';
    this.username = '';
    this.password = '';
    this.confirm_password = '';
    this.email2 = '';
    this.confirm_email = '';
    this.phone2 = '';
    this.system_role = GroupSystemRoleOptions.class_admin;
    this.clinical_role = ClinicalRoleOptions.Physician;
    this.active = true;
  }
}

export const GroupFormStepOneFieldsKeys = ['group_id', 'group_name', 'contact_name', 'email', 'phone'];

export const GroupFormStepTwoFieldsKeys = [
  'first_name',
  'last_name',
  'username',
  'password',
  'confirm_password',
  'email2',
  'confirm_email',
  'phone2',
  'system_role',
  'clinical_role',
  'active',
];

export const GroupFormRequiredFields = [
  'email',
  'phone',
  'group_name',
  'contact_name',
  'first_name',
  'last_name',
  'username',
  'password',
  'confirm_password',
  'email2',
  'confirm_email',
];

export enum TableActionsOptions {
  Edit = 'edit',
  Delete = 'delete',
  Move = 'move',
  Restore = 'restore',
}
