import './sideMenu.style.scss';
import { useContext } from 'react';
import AppContext from 'src/core/context/global/AppContext';
import { useTranslation } from 'react-i18next';
import { can, getUserRole, hideFeature } from 'src/core/utils/global.utils';
import { isSinghealthInstance } from 'src/core/utils/instance.utils';
import healbotAvatar from '../../../assets/healBot/healbot_avatar.png';
import healbot from '../../../assets/healBot/healbot.png';
import { Typography } from '@mui/material';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AddIcCallOutlinedIcon from '@mui/icons-material/AddIcCallOutlined';
import { Structure } from 'src/core/models/session.models';
interface Props {
  openPage: any;
  signOut: any;
  getItemClassName: any;
  openTelehealth: any;
  openAbout: any;
}

function SiteMenu(props: Props) {
  const { structure, user, setStructure, abilities, selectedAccount, setSelectedAccount } = useContext(AppContext);
  const { openPage, signOut, getItemClassName, openTelehealth, openAbout } = props;
  const { t } = useTranslation();

  const BackToSites = () => {
    if (structure.parent) {
      setStructure(structure.parent);
      openPage('/sites');
    }
  };
  return (
    <div className='side-menu'>
      <ul className='items'>
        {user.structures && can(getUserRole(user, structure), ['view_sites'], abilities) && (
          <li className={getItemClassName('/sites')} onClick={BackToSites}>
            <ArrowBackOutlinedIcon className='item-icon' />
            <Typography noWrap>{t('account.accountAdministration')}</Typography>
          </li>
        )}

        {hideFeature && (
          <li className={getItemClassName('/dashboard')} onClick={() => openPage('/dashboard')}>
            <HomeOutlinedIcon className='item-icon' />
            <Typography noWrap>{t('account.dashboard')}</Typography>
          </li>
        )}
        <li className={getItemClassName('/patients')} onClick={() => openPage('/patients')}>
          <AssignmentOutlinedIcon className='item-icon' />
          <Typography noWrap>{t('menu.patients')}</Typography>
        </li>

        <li className={getItemClassName('/users')} onClick={() => openPage('/users')}>
          <PeopleAltOutlinedIcon className='item-icon' />
          <Typography noWrap>{t('menu.userManagement')}</Typography>
        </li>
        {user.structures && can(undefined, ['access_sites'], abilities) && (
          <li className={getItemClassName('/sites')} onClick={() => openPage('/sites')}>
            <HomeOutlinedIcon className='item-icon' />
            <Typography noWrap>{t('menu.sites')}</Typography>
          </li>
        )}
        {!isSinghealthInstance() && can(structure, ['export_csv'], abilities) && (
          <li className={getItemClassName('/reports')} onClick={() => openPage('/reports')}>
            <AssignmentOutlinedIcon className='item-icon' />
            <Typography noWrap>{t('menu.report')}</Typography>
          </li>
        )}
        {hideFeature && (
          <li className={'item'} onClick={openTelehealth}>
            <AddIcCallOutlinedIcon className='item-icon' />
            <Typography noWrap>{t('menu.telehealth')}</Typography>
          </li>
        )}
        {structure.healbot_enabled && can(structure, ['healbot'], abilities) && (
          <li className={getItemClassName('/healBot')} onClick={() => openPage('/healBot')}>
            <img src={healbotAvatar} alt='healbotAvatar' className='healbotAvatar-icon' />
            <img src={healbot} alt='healbot' className='healbot-text' />
          </li>
        )}
        <li className={getItemClassName('/settings')} onClick={() => openPage('/settings')}>
          <SettingsOutlinedIcon className='item-icon' />
          <Typography noWrap>{t('menu.settings')}</Typography>
        </li>
        {!isSinghealthInstance() && (
          <li className={'item'}>
            <a href={'https://ekareinchelp.zendesk.com'} target='_blank' rel='noopener noreferrer'>
              <HelpOutlineOutlinedIcon className='item-icon' />
              <Typography noWrap>{t('menu.help')}</Typography>
            </a>
          </li>
        )}
        <li className={getItemClassName('/about')} onClick={openAbout}>
          <InfoOutlinedIcon className='item-icon' />
          <Typography noWrap>{t('menu.about')}</Typography>
        </li>
        <div className='bottom-items'>
          <li key={'signOut'} className='item' onClick={signOut}>
            <LogoutOutlinedIcon className='item-icon' />
            <Typography noWrap>{t('menu.signOut')}</Typography>
          </li>
        </div>
      </ul>
    </div>
  );
}

export default SiteMenu;
